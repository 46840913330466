import React from 'react'
import { RichText } from 'prismic-reactjs'
import { StaticQuery, graphql } from 'gatsby'

import {
  Box,
  DisplayText,
  DonationsBlock,
  GivingCard,
  FlexCol,
  FlexGrid,
  H2,
  H3,
  Highlight,
  List,
  SEO,
} from 'src/components'

import serializer from 'src/utils/serializer'
import linkResolver from 'src/utils/linkResolver'

const Donate = () => (
  <StaticQuery
    query={graphql`
      query {
        donate: prismicGivePage {
          dataString
        }
        campaigns: allPrismicCampaign {
          edges {
            node {
              uid
              id
              data {
                campaign_image {
                  fluid(maxWidth: 1200) {
                    ...GatsbyPrismicImageFluid
                  }
                }
                campaign_title {
                  text
                }
                campaign_description {
                  text
                }
                campaign_color
              }
            }
          }
        }
        products: allPrismicProduct {
          edges {
            node {
              uid
              id
              data {
                product_image {
                  fluid(maxWidth: 1200) {
                    ...GatsbyPrismicImageFluid
                  }
                }
                product_color
                product_name {
                  text
                }
                product_description {
                  text
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      let page = JSON.parse(data.donate.dataString)
      return (
        <>
          <SEO
            title={
              page.opengraph_title ? RichText.asText(page.opengraph_title) : ''
            }
            description={
              page.opengraph_description
                ? RichText.asText(page.opengraph_description)
                : ''
            }
            image={page.opengraph_image ? page.opengraph_image.url : ''}
          />
          <Box px={[3, null, null, 4]} py={4} bg="bg.default">
            <Box maxWidth={1280} mx="auto">
              <FlexCol alignItems="center">
                <DisplayText
                  mb="0.5em"
                  textAlign="center"
                  style={{ transform: 'rotate(-7.5deg)' }}
                >
                  <Highlight variant={2}>
                    {RichText.asText(page.hero_header)}
                  </Highlight>
                </DisplayText>
              </FlexCol>
            </Box>
          </Box>
          <Box px={[2, null, 3, 4]} pb={[4, 5]}>
            <DonationsBlock />
          </Box>
          <Box px={[3, null, null, 4]} py={[4, 5]} bg="bg.wash">
            <Box maxWidth={1280} mx="auto">
              <Box maxWidth={640} mx="auto" mb="4em">
                <H2
                  children={RichText.asText(page.impact_header)}
                  mb="0.5em"
                  textAlign="center"
                />
                <span style={{ textAlign: 'center' }}>
                  {RichText.render(page.impact_body, linkResolver, serializer)}
                </span>
              </Box>
              <FlexGrid gutterX={[3, 4]} gutterY={[4]}>
                <Box width={[1, null, 1 / 2]}>
                  <H3 children={`Donate Once`} mb="1em" color="brand.yellow" />
                  <List>
                    {page.one_time_impact.map(impact => (
                      <li children={impact.impact} />
                    ))}
                  </List>
                </Box>
                <Box width={[1, null, 1 / 2]}>
                  <H3
                    children={`Donate Monthly`}
                    mb="1em"
                    color="brand.yellow"
                  />
                  <List>
                    {page.monthly_impact.map(impact => (
                      <li children={impact.impact} />
                    ))}
                  </List>
                </Box>
              </FlexGrid>
            </Box>
            {(data.campaigns || data.products) && (
              <Box width={1} maxWidth={1280} mx="auto" my={4}>
                <H3 children={`Other ways to give`} mb={3} textAlign="center" />
                <FlexGrid gutterY={[3]}>
                  {data.campaigns && (
                    <>
                      {data.campaigns.edges.map((campaign, index) => {
                        return (
                          <GivingCard
                            color={campaign.node.data.campaign_color.toLowerCase()}
                            image={
                              campaign.node.data.campaign_image
                                ? campaign.node.data.campaign_image
                                  ? campaign.node.data.campaign_image.fluid
                                  : undefined
                                : undefined
                            }
                            title={
                              campaign.node.data.campaign_title
                                ? campaign.node.data.campaign_title.text
                                : ''
                            }
                            description={
                              campaign.node.data.campaign_description
                                ? campaign.node.data.campaign_description.text
                                : ''
                            }
                            link={'/campaign/' + campaign.node.uid}
                          />
                        )
                      })}
                    </>
                  )}
                  {data.products && (
                    <>
                      {data.products.edges.map(product => {
                        return (
                          <GivingCard
                            color={product.node.data.product_color.toLowerCase()}
                            image={
                              product.node.data.product_image
                                ? product.node.data.product_image
                                  ? product.node.data.product_image.fluid
                                  : undefined
                                : undefined
                            }
                            title={
                              product.node.data.product_name
                                ? product.node.data.product_name.text
                                : ''
                            }
                            description={
                              product.node.data.product_description
                                ? product.node.data.product_description.text
                                : ''
                            }
                            link={'/product/' + product.node.uid}
                          />
                        )
                      })}
                    </>
                  )}
                </FlexGrid>
              </Box>
            )}
          </Box>
        </>
      )
    }}
  />
)

export default Donate
